import * as React from "react";
import { useEffect, useRef, useState } from "react";

const headingStyles = {
  textAlign: "center",
  color: "white",
  fontSize: "7em",
  position: "absolute",
  top: "50%",
  backgroundColor: "rgba(0,0,0,0.6)",
  padding: "4px 40px",
};

const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  height: "100vh",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const IndexPage = ({ location }) => {
  const bannerTextRef = useRef(null);
  const [bannerText, setBannerText] = useState("Scott's Cheap Flights")

  useEffect(() => {
    let backgroundClass = '';
    const host = location.host ? location.host : '';
    switch (host) {
      case "scotchcheapflights.com":
        backgroundClass = 'scotch';
        setBannerText("Scotch Cheap Flights");
        break;
      case "scottssheepflights.com":
        backgroundClass = 'sheep';
        setBannerText("Scott's Sheep Flights");
        break;
      case "scottscheapfights.club":
        backgroundClass = 'fights';
        setBannerText("Scott's Cheap Fights");
        break;
      default:
        backgroundClass = 'flights';
        break;
    }
    const containerDiv = document.querySelector("#containerDiv");
    containerDiv.classList.add(backgroundClass);
  }, [location.host]);

  return (
    <main id='containerDiv' style={pageStyles}>
      <h1 style={headingStyles}>{bannerText}</h1>
    </main>
  );
};

export default IndexPage;
